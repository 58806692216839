@import '../../../styles/settings.scss';
@import '../../../styles/global.scss';

.root {
    position: sticky;
    height: 80px;
    background: $color-black;
    color: $color-white;
    display: flex;
    .leftBox {
        flex: 0 0 80%;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        h2 {
            padding-left: 30px;
            margin: 0;
        }
    }
    .rightBox {
        flex: 0 0 20%;
        justify-content: center;
        align-items: center;
        .link {
            display: inline-flex;
            height: 100%;
            width: 100%;
            justify-content: flex-end;
            align-items: center;
            padding-right: 30px;
            .icon {
                cursor: pointer;
                color: $color-white;
                transition: 4s all;
                font-size: 32px;
                &:hover {
                    transform: scale(1.6);
                }
            }
        }
    }
}


@include mobile {
    .root {
        .leftBox {
            h2 {
                font-size: 18px;
            }
        }
    }
}
@import '../../../styles/settings.scss';

.root {
    display: inline-block;
    min-width: 200px;
    margin: 24px auto 0 auto;
    padding: 16px;
    border-radius: 5px;
    font-weight: bold;
    font-family: $font-main;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    background-position: 100% 0;
    background-size: 300%;
    transition: $transition;
    border: 3px solid $color-white;
    color: $color-white;
    background-image: linear-gradient(90deg, $color-white 50%, transparent 50%);
    &:hover {
        background-position: 0 100%;
        color: $color-violet;
        border-bottom: 3px solid transparent;
        border-top: 3px solid $color-white;
        border-right: 3px solid $color-white;
        border-left: 3px solid $color-white;
    }
}
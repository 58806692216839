@import url('https://fonts.googleapis.com/css2?family=Quicksand&family=Source+Sans+Pro&display=swap');

$base-size: 12px;
$color-violet: #302b63;
$color-black: #0E0E0E;
$color-red: #d63031;
$color-white:	#F6F4F3;
$font-logo: 'Source Sans Pro', sans-serif;
$font-main: 'Quicksand', sans-serif;
$transition: all 1s;

@import '../../../styles/settings.scss';


.root {
    height: 100vh;
    position: relative;
    h2 {
        color: $color-white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .canvas {
        transition: 5s all;
    }
}

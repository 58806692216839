@import url(https://fonts.googleapis.com/css2?family=Quicksand&family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand&family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand&family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand&family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand&family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand&family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand&family=Source+Sans+Pro&display=swap);
*, *::after, *::before {
  box-sizing: border-box; }

html,
body,
#root,
canvas {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%; }

body {
  background: linear-gradient(to right, #0f0c29, #302b63, #24243e);
  overflow: hidden;
  font-family: "Quicksand", sans-serif; }

@media (min-width: 577px) and (max-width: 1024px) {
  body {
    overflow: visible; } }

@media (max-width: 576px) {
  body {
    overflow: visible; } }

*, *::after, *::before {
  box-sizing: border-box; }

html,
body,
#Header_root__1poCT,
canvas {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%; }

body {
  background: linear-gradient(to right, #0f0c29, #302b63, #24243e);
  overflow: hidden;
  font-family: "Quicksand", sans-serif; }

@media (min-width: 577px) and (max-width: 1024px) {
  body {
    overflow: visible; } }

@media (max-width: 576px) {
  body {
    overflow: visible; } }

.Header_root__1poCT {
  position: -webkit-sticky;
  position: sticky;
  height: 80px;
  background: #0E0E0E;
  color: #F6F4F3;
  display: flex; }
  .Header_root__1poCT .Header_leftBox__AO56Y {
    flex: 0 0 80%;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column; }
    .Header_root__1poCT .Header_leftBox__AO56Y h2 {
      padding-left: 30px;
      margin: 0; }
  .Header_root__1poCT .Header_rightBox__nNTIx {
    flex: 0 0 20%;
    justify-content: center;
    align-items: center; }
    .Header_root__1poCT .Header_rightBox__nNTIx .Header_link__1IHUq {
      display: inline-flex;
      height: 100%;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      padding-right: 30px; }
      .Header_root__1poCT .Header_rightBox__nNTIx .Header_link__1IHUq .Header_icon__3rFMM {
        cursor: pointer;
        color: #F6F4F3;
        transition: 4s all;
        font-size: 32px; }
        .Header_root__1poCT .Header_rightBox__nNTIx .Header_link__1IHUq .Header_icon__3rFMM:hover {
          -webkit-transform: scale(1.6);
                  transform: scale(1.6); }

@media (max-width: 576px) {
  .Header_root__1poCT .Header_leftBox__AO56Y h2 {
    font-size: 18px; } }

.Footer_root__1_sle {
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 80px;
  background: #0E0E0E;
  color: #F6F4F3;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .Footer_root__1_sle .Footer_mediaBox__3GE3s {
    display: flex;
    flex-direction: column;
    margin-left: 20px; }
    .Footer_root__1_sle .Footer_mediaBox__3GE3s a {
      text-decoration: none;
      color: #F6F4F3; }
      .Footer_root__1_sle .Footer_mediaBox__3GE3s a:hover {
        color: #302b63; }
  .Footer_root__1_sle .Footer_spanBox__qAn9y {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-right: 20px; }



.Homepage_root__SeR2I {
  height: 100vh;
  position: relative; }
  .Homepage_root__SeR2I h2 {
    color: #F6F4F3;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .Homepage_root__SeR2I .Homepage_canvas__1Yhgt {
    transition: 5s all; }

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; }
  body .Loading_loader__2MMJW {
    width: 6em;
    height: 6em;
    font-size: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    body .Loading_loader__2MMJW .Loading_face__1kpCX {
      position: absolute;
      border-radius: 50%;
      border-style: solid;
      -webkit-animation: Loading_animate023845__tFXi5 3s linear infinite;
              animation: Loading_animate023845__tFXi5 3s linear infinite; }
      body .Loading_loader__2MMJW .Loading_face__1kpCX:nth-child(1) {
        width: 100%;
        height: 100%;
        color: #fff;
        border-color: currentColor transparent transparent currentColor;
        border-width: 0.2em 0.2em 0em 0em;
        --deg: -45deg;
        -webkit-animation-direction: normal;
                animation-direction: normal; }
        body .Loading_loader__2MMJW .Loading_face__1kpCX:nth-child(1) .Loading_circle__2Eau4 {
          position: absolute;
          width: 50%;
          height: 0.1em;
          top: 50%;
          left: 50%;
          background-color: transparent;
          -webkit-transform: rotate(var(--deg));
                  transform: rotate(var(--deg));
          -webkit-transform-origin: left;
                  transform-origin: left; }
          body .Loading_loader__2MMJW .Loading_face__1kpCX:nth-child(1) .Loading_circle__2Eau4::before {
            position: absolute;
            top: -0.5em;
            right: -0.5em;
            content: '';
            width: 1em;
            height: 1em;
            background-color: currentColor;
            border-radius: 50%;
            box-shadow: 0 0 2em, 0 0 4em, 0 0 6em, 0 0 8em, 0 0 10em, 0 0 0 0.5em rgba(255, 255, 0, 0.1); }
      body .Loading_loader__2MMJW .Loading_face__1kpCX:nth-child(2) {
        width: 70%;
        height: 70%;
        color: #fff;
        border-color: currentColor currentColor transparent transparent;
        border-width: 0.2em 0em 0em 0.2em;
        --deg: -135deg;
        animation-direction: reverse; }
        body .Loading_loader__2MMJW .Loading_face__1kpCX:nth-child(2) .Loading_circle__2Eau4 {
          position: absolute;
          width: 50%;
          height: 0.1em;
          top: 50%;
          left: 50%;
          background-color: transparent;
          -webkit-transform: rotate(var(--deg));
                  transform: rotate(var(--deg));
          -webkit-transform-origin: left;
                  transform-origin: left; }
          body .Loading_loader__2MMJW .Loading_face__1kpCX:nth-child(2) .Loading_circle__2Eau4::before {
            position: absolute;
            top: -0.5em;
            right: -0.5em;
            content: '';
            width: 1em;
            height: 1em;
            background-color: currentColor;
            border-radius: 50%;
            box-shadow: 0 0 2em, 0 0 4em, 0 0 6em, 0 0 8em, 0 0 10em, 0 0 0 0.5em rgba(255, 255, 0, 0.1); }

@-webkit-keyframes Loading_animate023845__tFXi5 {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn); } }

@keyframes Loading_animate023845__tFXi5 {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn); } }

.Button_root__3XavS {
  display: inline-block;
  min-width: 200px;
  margin: 24px auto 0 auto;
  padding: 16px;
  border-radius: 5px;
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-position: 100% 0;
  background-size: 300%;
  transition: all 1s;
  border: 3px solid #F6F4F3;
  color: #F6F4F3;
  background-image: linear-gradient(90deg, #F6F4F3 50%, transparent 50%); }
  .Button_root__3XavS:hover {
    background-position: 0 100%;
    color: #302b63;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #F6F4F3;
    border-right: 3px solid #F6F4F3;
    border-left: 3px solid #F6F4F3; }

*, *::after, *::before {
  box-sizing: border-box; }

html,
body,
#Project_root__1QxTf,
canvas {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%; }

body {
  background: linear-gradient(to right, #0f0c29, #302b63, #24243e);
  overflow: hidden;
  font-family: "Quicksand", sans-serif; }

@media (min-width: 577px) and (max-width: 1024px) {
  body {
    overflow: visible; } }

@media (max-width: 576px) {
  body {
    overflow: visible; } }

.Project_root__1QxTf {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72vh; }
  .Project_root__1QxTf h2 {
    color: #F6F4F3; }
  .Project_root__1QxTf .Project_project__UafZb {
    border: 3px solid #F6F4F3;
    border-radius: 5px;
    width: 80%;
    height: 70%;
    display: flex;
    color: #F6F4F3;
    -webkit-transform: translateX(-120%);
            transform: translateX(-120%); }
    .Project_root__1QxTf .Project_project__UafZb .Project_projectLeft__3iJfd {
      flex: 0 0 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 3px solid #F6F4F3; }
      .Project_root__1QxTf .Project_project__UafZb .Project_projectLeft__3iJfd .Project_titleProject__31H5O {
        text-align: center;
        padding: 0 10px; }
    .Project_root__1QxTf .Project_project__UafZb .Project_projectRight__28feL {
      flex: 0 0 80%;
      display: flex;
      flex-direction: column; }
      .Project_root__1QxTf .Project_project__UafZb .Project_projectRight__28feL .Project_content__1HWsp {
        flex: 0 0 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom: 3px solid #F6F4F3; }
      .Project_root__1QxTf .Project_project__UafZb .Project_projectRight__28feL .Project_links__25-Np {
        flex: 0 0 50%;
        display: flex;
        justify-content: space-around;
        align-items: center; }
  .Project_root__1QxTf .Project_show__1L7ko {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    transition: all 1s; }

@media (min-width: 577px) and (max-width: 1024px) {
  .Project_root__1QxTf {
    height: 85vh; }
    .Project_root__1QxTf .Project_project__UafZb {
      flex-direction: column;
      height: 80%; }
      .Project_root__1QxTf .Project_project__UafZb .Project_projectLeft__3iJfd {
        border-bottom: 3px solid #F6F4F3;
        border-right: none; }
      .Project_root__1QxTf .Project_project__UafZb .Project_projectRight__28feL .Project_content__1HWsp {
        font-size: 18px;
        padding: 20px 30px; }
      .Project_root__1QxTf .Project_project__UafZb .Project_projectRight__28feL .Project_links__25-Np {
        justify-content: center;
        flex-direction: column;
        align-items: center; } }

@media (max-width: 576px) {
  .Project_root__1QxTf {
    height: 170vh;
    align-items: flex-start;
    margin-top: 40px; }
    .Project_root__1QxTf .Project_project__UafZb {
      flex-direction: column;
      height: 80%; }
      .Project_root__1QxTf .Project_project__UafZb .Project_projectLeft__3iJfd {
        border-bottom: 3px solid #F6F4F3;
        border-right: none; }
      .Project_root__1QxTf .Project_project__UafZb .Project_projectRight__28feL .Project_content__1HWsp {
        font-size: 18px;
        padding: 20px 30px; }
      .Project_root__1QxTf .Project_project__UafZb .Project_projectRight__28feL .Project_links__25-Np {
        justify-content: center;
        flex-direction: column;
        margin-right: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 30%; }
        .Project_root__1QxTf .Project_project__UafZb .Project_projectRight__28feL .Project_links__25-Np .Project_btnProjects__3XRe3 {
          height: 50%; } }

